<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="4" >
                <div  
                    style="width: 100%; max-width: 100%; height: 100%; max-height: 100%;">
                    <div class="justify-center align-center text-center" >
                        <v-img :src="item.cover" />
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="8">
                <p>{{ item.festival }}</p>
                <v-btn text @click="goTo(item.judgeCategory, item.festivalId)">
                    <img
                        height="24"
                        width="24"
                        src="@/assets/i-play.png"
                    />
                    <span class="ml-1">Painel {{item.judgeCategory}}</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import { JURY_PANEL } from '../../../../routes';

export default {
    props: {
        item: {
            type: Object
        }
    },
    data: () => ({
    }),
    methods: {
        goTo(painel, festivalId) {
            switch (painel) {
                case 'AUXILIAR':
                    this.$router.push({ name: JURY_PANEL.auxiliary.name, params: { festival: festivalId } });
                    break;
                case 'PRESIDENTE':
                    this.$router.push({ name: JURY_PANEL.festival.name, params: { festival: festivalId } });
                    break;
                case 'PRINCIPAL':
                    this.$router.push({ name: JURY_PANEL.main.name, params: { festival: festivalId } });
                    break;
                case 'JURY_2':
                    this.$router.push({ name: JURY_PANEL.jury.name, params: { festival: festivalId } });
                    break;
                default:
                    break;
            }
        }
    }
}
</script>