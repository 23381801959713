<template>
    <v-container style="margin: 0; padding: 0">
        <v-row>
            <v-col cols="12">
                <v-subheader class="headline"><span class="primary--text">PAINEL JÚRI > </span> Festival</v-subheader>
                  <p-breadcrumbs page="profile.jury-panel.home.index" />
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-alert
                    outlined
                    type="warning"
                    prominent
                    border="left"
                    v-if="items.length == 0"
                >
                    Lamentamos, mas ainda não tem nenhum festival.
                </v-alert>
            </v-col>
        </v-row>
        
        <div class="body pl-2x pr-2x pt-1x pb-1x" >
            
            <template v-for="(item,i) in festivals">
                <v-divider v-if="(i+1)%2 === 0" class="ml-2x mr-2x" :key="`d-${item.festival.id}`"/>
                <Festival :item="item"  :key="item.festival.id"/>
            </template>
        </div>

        <!--v-container v-if="hasMore">
            <v-row align="center" justify="center">
                <v-btn 
                :loading="loading" 
                :disabled="loading"
                @click="fetchMore">Carregue Mais</v-btn>
            </v-row>
        </v-container-->
    </v-container>
</template>
<script>
import { HOME } from '../../../../routes';
import Festival from '../../../../components/author/jury-panel/home/Festival';
import { mapState, mapActions } from 'vuex';
export default {
    components: {
        Festival,
    },
    data: () => ({
        
    }),
    created: function () {
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
        this.fetchItems(this.filter);
    },
    computed: {
        ...mapState('juryPanel/home', ['items', 'loading',  'hasMore', 'festivals']),
        
        filter() {
            return { 
                limit: 20,
                id: this.$user.info.id,
            }
        },
        
        user() {
            if (this.$user.isAuthenticated)
                return this.$user.info;
            return null;
        },
    },
    methods: {
        ...mapActions('juryPanel/home', {
            fetchItems: 'list',
            loadMore: 'loadMore',
            myFestival: 'myFestival'
        }),

        fetchMore() {
            this.loadMore(this.filter);
        },

    },

    mounted() {
        this.myFestival({ user: this.$user.info.id  })
    }
}
</script>